interface IConfig {
  AUTH0_CLIENT_ID: string
  AUTH0_CLIENT_DOMAIN: string
  AUTH0_AUDIENCE: string
  AUTH0_CALLBACK: string
  API: string
}

export const Env: IConfig = {
  API: process.env.REACT_APP_API || 'https://api.vax-mgmt.skl.io',
  AUTH0_AUDIENCE: 'https://internal-api.prod.skl.io/',
  AUTH0_CALLBACK: process.env.REACT_APP_AUTH0_CALLBACK || 'https://console.vax-mgmt.skl.io/callback',
  AUTH0_CLIENT_DOMAIN: 'skedulo-internal.au.auth0.com',
  AUTH0_CLIENT_ID: 'VrBB4VCAKYzSQDkEakrIEgjOtW4XrFUS'
}
